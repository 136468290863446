import Component from "./component";
import {useEscape} from "./escape";
import {useBackdrop} from "./backdrop";
import {useTransitionEnd} from "./transitionEnd";
import {noop, checkIf, isDomElement, delegate} from "./utils";

const MODAL_TARGET_SELECTOR = '[ty-modal]';
const MODAL_TARGET_ATTRIBUTE = 'ty-modal';
const MODAL_VISIBLE_SELECTOR = '[ty-modal-visible]';
const MODAL_VISIBLE_ATTRIBUTE = 'ty-modal-visible';
const MODAL_BACKDROP_ATTRIBUTE = 'ty-modal-backdrop';
const MODAL_CLOSABLE_ATTRIBUTE = 'ty-modal-closable';

class Modal extends Component
{
    element = undefined;

    options = Modal.defaultOptions;

    static defaultOptions = {
        closable: true,
        backdrop: true,
        beforeShow: noop,
        afterShow: noop,
        beforeHide: noop,
        afterHide: noop,
        beforeToggle: noop,
        afterToggle: noop,
        transitionClass: 'modal-fade',
    };

    constructor(element, options = {})
    {
        super();

        this.element = element;

        this.options.backdrop = checkIf( this.element, MODAL_BACKDROP_ATTRIBUTE );
        this.options.closable = checkIf( this.element, MODAL_CLOSABLE_ATTRIBUTE );
        this.options = {...this.options, ...options};
    }

    show()
    {
        if ( typeof this.options.beforeShow === "function" ) this.options.beforeShow( this );

        this.element.setAttribute( MODAL_VISIBLE_ATTRIBUTE, true );
        this.element.setAttribute('aria-modal', true );
        this.element.setAttribute('aria-hidden', 'false');
        this.element.setAttribute('role', 'dialog');

        if ( this.options.backdrop ) this.showBackdrop();

        this.element.style.display = 'block';
        this.element.classList.add( this.options.transitionClass );
        this.element.style.opacity = '0';

        this.transitionEnd( () => {
            this.element.classList.remove( this.options.transitionClass );

            this.element.style.opacity = '';
        }, this.element );

        this.element.style.opacity = '1';

        if ( this.options.closable ) {
            this.escape( () => {
                this.hide();
            } );
        }

        if ( typeof this.options.afterShow === "function" ) this.options.afterShow( this );
    }

    hide()
    {
        if ( typeof this.options.beforeHide === "function" ) this.options.beforeHide( this );

        this.element.removeAttribute( MODAL_VISIBLE_ATTRIBUTE );
        this.element.removeAttribute('aria-modal');
        this.element.setAttribute('aria-hidden', 'true');
        this.element.removeAttribute('role');

        if ( this.options.backdrop ) this.hideBackdrop();

        this.element.style.opacity = '1';

        this.element.classList.add( this.options.transitionClass );

        this.transitionEnd( () => {
            this.element.classList.remove( this.options.transitionClass );
            this.element.style.display = 'none';
        }, this.element );

        this.element.style.opacity = '';

        if ( typeof this.options.afterHide === "function" ) this.options.afterHide( this );
    }

    toggle()
    {
        if ( typeof this.options.beforeToggle === "function" ) this.options.beforeToggle( this );

        this.isVisible() ? this.hide() : this.show();

        if ( typeof this.options.afterToggle === "function" ) this.options.afterToggle( this );
    }

    isVisible()
    {
        return this.element.hasAttribute( MODAL_VISIBLE_ATTRIBUTE );
    }

    isHidden()
    {
        return !this.isVisible();
    }

    closable()
    {
        if ( this.options.closable ) {
            return this;
        }

        return {hide: noop}
    }
}

useBackdrop( Modal );
useEscape( Modal );
useTransitionEnd( Modal );

function initModals()
{
    delegate( MODAL_TARGET_SELECTOR, 'click', function () {
        Modal.getInstanceOrCreate(
            document.querySelector( this.getAttribute( MODAL_TARGET_ATTRIBUTE ) )
        ).toggle();
    } );

    delegate( MODAL_VISIBLE_SELECTOR, 'click', function (event) {
        if ( this === event.target ) {
            Modal.getInstanceOrCreate( this ).closable().hide();
        }
    } );

    document.addEventListener('modal:toggle', event => {
        Modal.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).toggle();
    } );

    document.addEventListener('modal:show', event => {
        Modal.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).show();
    } );

    document.addEventListener('modal:hide', event => {
        Modal.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).hide();
    } );
}

export default {
    Modal,
    initModals
}
