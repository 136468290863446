import Component from "./component";
import {noop, isDomElement, delegate, getElementSize} from "./utils";
import {useTransitionEnd} from "./transitionEnd";

const ACCORDION_TARGET_SELECTOR = '[ty-accordion]';
const ACCORDION_TARGET_ATTRIBUTE = 'ty-accordion';
const ACCORDION_OPEN_ATTRIBUTE = 'ty-accordion-open';
const ACCORDION_PARENT_ATTRIBUTE = 'ty-accordion-parent';

class Accordion extends Component
{
    element = undefined;
    target = undefined;

    options = {};

    _inTransitioning = false;

    static defaultOptions = {
        beforeShow: noop,
        afterShow: noop,
        beforeHide: noop,
        afterHide: noop,
        beforeToggle: noop,
        afterToggle: noop,
        activeClasses: '',
        inactiveClasses: '',
        transitionClass: 'accordion-collapse',
    };

    constructor(element, options = {})
    {
        super();

        this.element = element;

        this.options = {...Accordion.defaultOptions, ...options};
    }

    init()
    {
        if ( this.isHidden() ) {
            const target = document.querySelector( this.element.getAttribute( ACCORDION_TARGET_ATTRIBUTE ) );
            target.style.display = 'none';

            if ( this.options.inactiveClasses.trim().length > 0 ) this.element.classList.add( ...this.options.inactiveClasses.split(' ') );
            if ( this.options.activeClasses.trim().length > 0 ) this.element.classList.remove( ...this.options.activeClasses.split(' ') );
        } else {
            if ( this.options.activeClasses.trim().length > 0 ) this.element.classList.add( ...this.options.activeClasses.split(' ') );
            if ( this.options.inactiveClasses.trim().length > 0 ) this.element.classList.remove( ...this.options.inactiveClasses.split(' ') );
        }
    }

    show()
    {
        if ( this._inTransitioning ) {
            return;
        }

        if ( typeof this.options.beforeShow === "function" ) this.options.beforeShow( this );

        if ( this.element.hasAttribute( ACCORDION_PARENT_ATTRIBUTE ) ) {
            const parent = this.element.closest( this.element.getAttribute( ACCORDION_PARENT_ATTRIBUTE ) );
            parent.querySelectorAll( '[' + ACCORDION_PARENT_ATTRIBUTE + '="' + this.element.getAttribute( ACCORDION_PARENT_ATTRIBUTE ) + '"]' )
                .forEach( item => {
                    const instance = Accordion.getInstanceOrCreate( item );

                    if ( instance.isVisible() ) instance.hide();
                } );
        }

        this.element.setAttribute('aria-expanded', 'true' );
        this.element.setAttribute( ACCORDION_OPEN_ATTRIBUTE, true );

        if ( this.options.activeClasses.trim().length > 0 ) this.element.classList.add( ...this.options.activeClasses.split(' ') );
        if ( this.options.inactiveClasses.trim().length > 0 ) this.element.classList.remove( ...this.options.inactiveClasses.split(' ') );

        const target = document.querySelector( this.element.getAttribute( ACCORDION_TARGET_ATTRIBUTE ) );

        this._inTransitioning = true;

        const height = getElementSize( target )['height'];

        target.style.display = 'block';
        target.classList.add( this.options.transitionClass );

        target.style.height = 0;

        this.transitionEnd( () => {
            this._inTransitioning = false;

            target.classList.remove( this.options.transitionClass );

            target.style.height = '';
        }, target );

        target.style.height = height + 'px';

        if ( typeof this.options.afterShow === "function" ) this.options.afterShow( this );
    }

    hide()
    {
        if ( this._inTransitioning ) {
            return;
        }

        if ( typeof this.options.beforeHide === "function" ) this.options.beforeHide( this );

        this.element.setAttribute('aria-expanded', 'false' );
        this.element.removeAttribute( ACCORDION_OPEN_ATTRIBUTE );

        if ( this.options.inactiveClasses.trim().length > 0 ) this.element.classList.add( ...this.options.inactiveClasses.split(' ') );
        if ( this.options.activeClasses.trim().length > 0 ) this.element.classList.remove( ...this.options.activeClasses.split(' ') );

        const target = document.querySelector( this.element.getAttribute( ACCORDION_TARGET_ATTRIBUTE ) );

        const height = getElementSize( target )['height'];
        target.style.height = height + 'px';

        this._inTransitioning = true;

        target.classList.add( this.options.transitionClass );

        this.transitionEnd( () => {
            this._inTransitioning = false;

            target.classList.remove( this.options.transitionClass );
            target.style.display = 'none';
        }, target );

        target.style.height = '';

        if ( typeof this.options.afterHide === "function" ) this.options.afterHide( this );
    }

    toggle()
    {
        if ( this._inTransitioning ) {
            return;
        }

        if ( typeof this.options.beforeToggle === "function" ) this.options.beforeToggle( this );

        this.isVisible() ? this.hide() : this.show();

        if ( typeof this.options.afterToggle === "function" ) this.options.afterToggle( this );
    }

    isVisible()
    {
        return this.element.hasAttribute( ACCORDION_OPEN_ATTRIBUTE );
    }

    isHidden()
    {
        return !this.isVisible();
    }
}

useTransitionEnd( Accordion );

function initAccordions()
{
    delegate( ACCORDION_TARGET_SELECTOR, 'click', function () {
        Accordion.getInstanceOrCreate( this ).toggle();
    } );

    document.querySelectorAll( ACCORDION_TARGET_SELECTOR ).forEach( function (element) {
        Accordion.getInstanceOrCreate( element ).init();
    } );

    document.addEventListener('accordion:toggle', event => {
        Accordion.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).toggle();
    } );

    document.addEventListener('accordion:show', event => {
        Accordion.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).show();
    } );

    document.addEventListener('accordion:hide', event => {
        Accordion.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).hide();
    } );
}

export default {
    Accordion,
    initAccordions
}
