const heap = [];

document.addEventListener('keyup', event =>
{
    if ( event.key === 'Escape' ) {
        const handler = heap.pop();

        if ( handler ) handler();
    }
} );

export function useEscape(classObject)
{
    return Object.assign( classObject.prototype, {
        escape(handler)
        {
            heap.push( handler );
        },
    } );
}

export default {
    useEscape
}
