import {useEscape} from "./escape";
import {useBackdrop} from "./backdrop";
import {checkIf, isDomElement, noop, delegate} from "./utils";
import Component from "./component";
import {useTransitionEnd} from "./transitionEnd";

const DRAWER_TARGET_SELECTOR = '[ty-drawer]';
const DRAWER_TARGET_ATTRIBUTE = 'ty-drawer';
const DRAWER_VISIBLE_ATTRIBUTE = 'ty-drawer-visible';
const DRAWER_BACKDROP_ATTRIBUTE = 'ty-drawer-backdrop';
const DRAWER_CLOSABLE_ATTRIBUTE = 'ty-drawer-closable';

class Drawer extends Component
{
    element = undefined;

    options = Drawer.defaultOptions;

    _callback = undefined;

    static defaultOptions = {
        closable: true,
        backdrop: true,
        beforeShow: noop,
        afterShow: noop,
        beforeHide: noop,
        afterHide: noop,
        beforeToggle: noop,
        afterToggle: noop,
    };

    constructor(element, options = {})
    {
        super();

        this.element = element;

        this.options.backdrop = checkIf( this.element, DRAWER_BACKDROP_ATTRIBUTE );
        this.options.closable = checkIf( this.element, DRAWER_CLOSABLE_ATTRIBUTE );
        this.options = {...this.options, ...options};
    }

    show()
    {
        if ( typeof this.options.beforeShow === "function" ) this.options.beforeShow( this );

        this.element.setAttribute( DRAWER_VISIBLE_ATTRIBUTE, true );
        this.element.setAttribute('aria-drawer', true );
        this.element.setAttribute('aria-hidden', 'false');
        this.element.setAttribute('role', 'dialog');

        if ( this.options.backdrop ) this.showBackdrop();

        if ( this.options.closable ) {
            this.escape( () => {
                this.hide();
            } );

            this._callback = event => {
                if ( event.target.hasAttribute('ty-backdrop') ) {
                    this.hide();
                }
            };

            document.addEventListener('click', this._callback );
        }

        if ( typeof this.options.afterShow === "function" ) this.options.afterShow( this );
    }

    hide()
    {
        if ( typeof this.options.beforeHide === "function" ) this.options.beforeHide( this );

        document.removeEventListener('click', this._callback );
        this.element.removeAttribute( DRAWER_VISIBLE_ATTRIBUTE );
        this.element.removeAttribute('aria-drawer');
        this.element.setAttribute('aria-hidden', 'true');
        this.element.removeAttribute('role');

        this.hideBackdrop();

        if ( typeof this.options.afterHide === "function" ) this.options.afterHide( this );
    }

    toggle()
    {
        if ( typeof this.options.beforeToggle === "function" ) this.options.beforeToggle( this );

        this.isVisible() ? this.hide() : this.show();

        if ( typeof this.options.afterToggle === "function" ) this.options.afterToggle( this );
    }

    isVisible()
    {
        return this.element.hasAttribute( DRAWER_VISIBLE_ATTRIBUTE );
    }

    isHidden()
    {
        return !this.isVisible();
    }

    closable()
    {
        if ( this.options.closable ) {
            return this;
        }

        return {hide: noop}
    }
}

useBackdrop( Drawer );
useEscape( Drawer );
useTransitionEnd( Drawer );

function initDrawers()
{
    delegate( DRAWER_TARGET_SELECTOR, 'click', function (event) {
        event.stopImmediatePropagation();

        Drawer.getInstanceOrCreate(
            document.querySelector( this.getAttribute( DRAWER_TARGET_ATTRIBUTE ) )
        ).toggle();
    } );

    document.addEventListener('drawer:toggle', event => {
        Drawer.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).toggle();
    } );

    document.addEventListener('drawer:show', event => {
        Drawer.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).show();
    } );

    document.addEventListener('drawer:hide', event => {
        Drawer.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).hide();
    } );
}

export default {
    Drawer,
    initDrawers
}
