<script setup>
import {ref, watch} from "vue";
import aos from 'aos';

const props = defineProps(['titles', 'sections']);

const currentSectionId = ref(0);
const currentSection = ref( props.sections[ 0 ] );

watch( currentSectionId, newValue => {
    currentSection.value = props.sections[ newValue ];

    aos.refresh();
} );

</script>

<template>
    <div>
        <div class="flex items-center justify-evenly overflow-auto lg:overflow-visible mb-10">
            <ul class="flex flex-row flex-nowrap justify-between gap-x-10 lg:gap-x-5 border-b-2 border-white lg:w-full">
                <li class="text-center lg:text-left lg:w-auto" v-for="(title, index) in props.titles">
                    <button class="w-auto whitespace-nowrap lg:w-auto text-lg font-sans font-normal inline-block relative pb-5
transition-colors after:transition-colors duration-150 after:duration-150
hover:text-copper after:absolute after:-bottom-[2px] after:inset-x-0 after:h-[2px] hover:after:bg-copper"
:class="{
'text-copper after:bg-copper' : currentSectionId === index,
'text-white after:bg-white' : currentSectionId !== index
 }" @click="currentSectionId = index">{{ title }}</button>
                </li>
            </ul>
        </div>

        <div v-for="$subsection in currentSection['subsections']">
            <div class="lg:pr-[100px] text-white text-lg font-bold my-10" v-if="$subsection['title']">{{ $subsection['title'] }}</div>

            <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-[100px] gap-y-10 lg:pr-[100px]">
                <div v-for="$element in $subsection['elements']">
                    <div class="flex flex-row justify-between mb-2.5">
                        <div class="text-white text-lg font-normal font-sans mr-5">{{ $element['name'] }}</div>

                        <div class="text-white text-lg font-normal font-sans text-right">{{ $element['price'] }}</div>
                    </div>

                    <div class="text-white text-base font-normal font-sans mr-20">
                        {{ $element['composition'] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>