export default class CancelablePromise
{
    constructor(callback)
    {
        this.isPending = true;
        this.isCanceled = false;

        this.cancelHandler = () => {};

        this.promise = new Promise( (resolve, reject) => {
            const onResolve = value => {
                this.isPending = true;
                resolve( value );
            };

            const onReject = reason => {
                this.isPending = false;
                reject( reason );
            };

            const onCancel = handler => {
                if ( !this.isPending ) {
                    throw "The `onCancel` handler was attached after the promise settled.";
                }

                this.cancelHandler = handler;
            }

            return callback( onResolve, onReject, onCancel );
        } );
    }

    then( onFulfilled, onRejected )
    {
        this.promise.then( onFulfilled, onRejected );

        return this;
    }

    catch(onRejected)
    {
        this.promise.catch( onRejected );

        return this;
    }

    cancel(reason)
    {
        if ( !this.isPending || this.isCanceled ) {
            return;
        }

        this.cancelHandler( reason );

        this.isCanceled = true;
    }
}
