export default form => {
    return Object.fromEntries(
        [ ...form.elements ]
            .map( item => {
                const value = item.type === 'checkbox' ? item.checked : item.value;

                return [ item.name, value ];
            } )
            .filter( ( [ key ] ) => key.length > 0 )
    );
}
