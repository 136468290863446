import modalComponent from './modal';
import drawerComponent from './drawer';
import dropdownComponent from './dropdown';
import accordionsComponent from './accordion';
import backdropComponent from './backdrop';

function typhoonFn()
{
    modalComponent.initModals();
    drawerComponent.initDrawers();
    dropdownComponent.initDropdowns();
    accordionsComponent.initAccordions();
}

export default typhoonFn;

export const typhoon = typhoonFn;

export const Modal = modalComponent.Modal;
export const initModals = modalComponent.initModals;

export const Drawer = drawerComponent.Drawer;
export const initDrawers = drawerComponent.initDrawers;

export const Dropdown = dropdownComponent.Dropdown;
export const initDropdowns = dropdownComponent.initDropdowns;
export const getDropdown = dropdownComponent.getDropdown;

export const Accordion = accordionsComponent.Accordion;
export const initAccordions = accordionsComponent.initAccordions;

export const Backdrop = backdropComponent.Backdrop;
