const getTransitionDuration = element => {
    let { transitionDuration, transitionDelay } = window.getComputedStyle( element );

    transitionDuration = transitionDuration.split(',')[0]
    transitionDelay = transitionDelay.split(',')[0]

    return ( Number.parseFloat( transitionDuration ) + Number.parseFloat( transitionDelay ) ) * 1000;
}

const TRANSITION_END = 'transitionend';

export function useTransitionEnd(classObject)
{
    return Object.assign( classObject.prototype, {
        transitionEnd(handler, element, waitUntilEnd = true)
        {
            if ( !waitUntilEnd ) {
                return handler();
            }

            const duration = getTransitionDuration( element );

            let called = false;

            const callback = () => {
                called = true;

                element.removeEventListener( TRANSITION_END , callback );
                handler();
            };

            element.addEventListener( TRANSITION_END , callback );

            setTimeout( () => {
                if ( !called ) {
                    element.dispatchEvent( new Event( TRANSITION_END ) );
                }
            }, duration );
        },
    } );
}

export default {
    useTransitionEnd
}
