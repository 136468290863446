/**
 * @param element
 * @param listeners
 */
export default (element, listeners) => Object.fromEntries(
    Object.entries( listeners ).map( ([event, callback]) => {
        element.addEventListener( event, callback );

        return [ event, () => element.removeEventListener( event, callback ) ];
    } )
);