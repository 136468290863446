export const noop = () => {};

export const checkIf = (element, attribute, defaultValue = true) => {
    const value = element.hasAttribute( attribute ) ? element.getAttribute( attribute ) : defaultValue;
    return value === true || value === 'true';
};

export const isDomElement = object => object instanceof HTMLElement;

export const delegate = (selector, event, handler) => {
    Element.prototype.addEventListener.call( document, event, function (event) {
        const matchingElement = event.target.closest( selector );

        return matchingElement !== null ? handler.call( matchingElement, event ) : false;
    }, true );
};

export const getElementSize = element => {
    const el_style  = window.getComputedStyle( element );
    const el_display = el_style.display;
    const el_position = el_style.position;
    const el_visibility = el_style.visibility;
    const el_max_height = el_style.maxHeight.replace('px', '').replace('%', '');
    let width, height;

    /** if it's not hidden we just return normal height */
    if ( el_display !== 'none' && el_max_height !== '0' ) {
        width = element.offsetWidth + parseInt( el_style.marginLeft, 10 ) + parseInt( el_style.marginRight, 10 );
        height = element.offsetHeight + parseInt( el_style.marginTop, 10 ) + parseInt( el_style.marginBottom, 10 );

        return { width, height };
    }

    /** the element is hidden, so we are making the element block, so we can measure its height but still be hidden */
    element.style.position   = 'absolute';
    element.style.visibility = 'hidden';
    element.style.display    = 'block';

    width = element.offsetWidth + parseInt( el_style.marginLeft, 10 ) + parseInt( el_style.marginRight, 10 );
    height = element.offsetHeight + parseInt( el_style.marginTop, 10 ) + parseInt( el_style.marginBottom, 10 );

    /** reverting to the original values */
    element.style.display = el_display;
    element.style.position = el_position === 'static' ? null : el_position;
    element.style.visibility = el_visibility === 'visible' ? null : el_visibility;

    return { width, height };
};
