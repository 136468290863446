import Component from "./component";
import {isDomElement, noop, delegate, getElementSize} from "./utils";
import {useEscape} from "./escape";
import {useTransitionEnd} from "./transitionEnd";

const DROPDOWN_TARGET_SELECTOR = '[ty-dropdown]';
const DROPDOWN_TARGET_ATTRIBUTE = 'ty-dropdown';
const DROPDOWN_OPEN_ATTRIBUTE = 'ty-dropdown-open';

class Dropdown extends Component
{
    element = undefined;

    options = Dropdown.defaultOptions;

    _callback = undefined;

    _inTransitioning = false;

    static defaultOptions = {
        beforeShow: noop,
        afterShow: noop,
        beforeHide: noop,
        afterHide: noop,
        beforeToggle: noop,
        afterToggle: noop,
        activeClasses: '',
        inactiveClasses: '',
        transitionClass: 'dropdown-collapse',
    };

    constructor(element, options = {})
    {
        super();

        this.element = element;

        this.options = {...this.options, ...options};
    }

    show()
    {
        if ( typeof this.options.beforeShow === "function" ) this.options.beforeShow( this );

        const target = document.querySelector( this.element.getAttribute( DROPDOWN_TARGET_ATTRIBUTE ) );

        if ( target ) {
            this._inTransitioning = true;

            this.escape( () => {
                this.hide();
            } );

            this._callback = event => {
                if ( !target.contains( event.target ) && !this.element.contains( event.target ) ) {
                    this.hide();
                }
            };

            document.addEventListener('click', this._callback );

            const height = getElementSize( target )['height'];

            this.element.setAttribute( DROPDOWN_OPEN_ATTRIBUTE, true );

            target.style.display = 'block';
            target.classList.add( this.options.transitionClass );

            target.style.height = 0;

            this.transitionEnd( () => {
                target.classList.remove( this.options.transitionClass );

                target.style.height = '';

                this._inTransitioning = false;
            }, target );

            target.style.height = height + 'px';
        }

        if ( typeof this.options.afterShow === "function" ) this.options.afterShow( this );
    }

    hide()
    {
        if ( typeof this.options.beforeHide === "function" ) this.options.beforeHide( this );

        const target = document.querySelector( this.element.getAttribute( DROPDOWN_TARGET_ATTRIBUTE ) );

        if ( target ) {
            const height = getElementSize( target )['height'];
            target.style.height = height + 'px';

            this._inTransitioning = true;

            target.classList.add( this.options.transitionClass );

            this.transitionEnd( () => {
                this._inTransitioning = false;

                target.classList.remove( this.options.transitionClass );
                target.style.display = 'none';
                this.element.blur();
                this.element.removeAttribute( DROPDOWN_OPEN_ATTRIBUTE );
            }, target );

            target.style.height = '';
        }

        if ( this._callback ) document.removeEventListener('click', this._callback );

        if ( typeof this.options.afterHide === "function" ) this.options.afterHide( this );
    }

    isVisible()
    {
        return this.element.hasAttribute( DROPDOWN_OPEN_ATTRIBUTE );
    }

    isHidden()
    {
        return !this.isVisible();
    }

    toggle()
    {
        if ( typeof this.options.beforeToggle === "function" ) this.options.beforeToggle( this );

        this.isVisible() ? this.hide() : this.show();

        if ( typeof this.options.afterToggle === "function" ) this.options.afterToggle( this );
    }
}

useEscape( Dropdown );
useTransitionEnd( Dropdown );

function initDropdowns()
{
    delegate( DROPDOWN_TARGET_SELECTOR, 'click', function () {
        Dropdown.getInstanceOrCreate(
            this
        ).toggle();
    } );

    document.addEventListener('dropdown:toggle', event => {
        Dropdown.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).toggle();
    } );

    document.addEventListener('dropdown:show', event => {
        Dropdown.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).show();
    } );

    document.addEventListener('dropdown:hide', event => {
        Dropdown.getInstanceOrCreate(
            isDomElement( event.detail ) ? event.detail : document.querySelector( event.detail )
        ).hide();
    } );
}

function getDropdown(selector)
{
    return Dropdown.getInstanceOrCreate( document.querySelector( selector ) );
}

export default {
    Dropdown,
    initDropdowns,
    getDropdown
}
