let heap = [];

const BACKDROP_SELECTOR = '[ty-backdrop]';
const BACKDROP_ATTRIBUTE = 'ty-backdrop';
const BACKDROP_OPENED_ATTRIBUTE = 'ty-backdrop-opened';

export class Backdrop {
    static options = {
        transitionClass: 'backdrop-fade',
    };

    static show()
    {
        heap.push( this );

        let backdrop = document.querySelector( BACKDROP_SELECTOR );

        if ( !backdrop ) {
            backdrop = document.createElement('div');
            backdrop.setAttribute( BACKDROP_ATTRIBUTE, true );
            document.body.setAttribute( BACKDROP_OPENED_ATTRIBUTE, true );
            document.body.appendChild( backdrop );

            backdrop.style.display = 'block';
            backdrop.classList.add( Backdrop.options.transitionClass );
            backdrop.style.opacity = '0';

            this.transitionEnd( () => {
                backdrop.classList.remove( Backdrop.options.transitionClass );

                backdrop.style.opacity = '';
            }, backdrop );

            backdrop.style.opacity = '1';
        }
    }

    static hide()
    {
        let backdrop = document.querySelector( BACKDROP_SELECTOR );

        const index = heap.indexOf( this );

        if ( index !== -1 ) heap.splice( index, 1 );

        if ( backdrop && heap.length <= 0 ) {

            backdrop.style.opacity = '1';

            backdrop.classList.add( Backdrop.options.transitionClass );

            this.transitionEnd( () => {
                backdrop.classList.remove( Backdrop.options.transitionClass );
                document.body.removeChild( backdrop );
                document.body.removeAttribute( BACKDROP_OPENED_ATTRIBUTE );
            }, backdrop );

            backdrop.style.opacity = '0';
        }
    }
}

export function useBackdrop(classObject)
{
    return Object.assign( classObject.prototype, {
        showBackdrop()
        {
            Backdrop.show.apply( this );
        },

        hideBackdrop()
        {
            Backdrop.hide.apply( this );
        }
    } );
}

export default {
    Backdrop,
    useBackdrop
}
