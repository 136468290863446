const instances = new Map();

export default class Component
{
    static getInstance(element)
    {
        return instances.get( element );
    }

    static getInstanceOrCreate(element, ...args)
    {
        if ( !instances.has( element ) ) {
            instances.set( element, new this( element, ...args ) );
        }

        return Component.getInstance( element );
    }
}
